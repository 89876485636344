import { createStore } from "vuex";

export default createStore({
  state: {
    overlayed: null,
  },
  getters: {
    overlayed(state) {
      return state.overlayed;
    },
  },
  mutations: {
    setOverlay(state, payload) {
      state.overlayed = payload.id;
    },
  },
  actions: {},
  modules: {},
});
