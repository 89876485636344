<template>
  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow sm:rounded-md text-left">
      <form @submit.prevent="sendMessage">
        <div class="px-4 py-5 bg-white rounded-t-md sm:p-6">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Nome *</label
              >
              <input
                type="text"
                name="name"
                id="name"
                required
                v-model="form.name"
                autocomplete="given-name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="surname"
                class="block text-sm font-medium text-gray-700"
                >Cognome *</label
              >
              <input
                type="text"
                name="surname"
                id="surname"
                required
                v-model="form.surname"
                autocomplete="family-name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6 sm:col-span-4">
              <label
                for="email-address"
                class="block text-sm font-medium text-gray-700"
                >Indirizzo email *</label
              >
              <input
                type="email"
                name="email-address"
                id="email-address"
                required
                v-model="form.email"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-6">
              <label
                for="about"
                class="block text-sm font-medium text-gray-700"
              >
                Messaggio *
              </label>
              <div class="mt-1">
                <textarea
                  id="about"
                  name="about"
                  rows="3"
                  required
                  v-model="form.message"
                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Scrivi qui il tuo messaggio"
                ></textarea>
              </div>
              <p class="mt-2 text-sm text-gray-500">
                Breve descrizione del motivo per cui ci scrivi.
              </p>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="business"
                class="block text-sm font-medium text-gray-700"
                >Azienda</label
              >
              <input
                type="text"
                name="business"
                v-model="form.business"
                id="business"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="phone" class="block text-sm font-medium text-gray-700"
                >Cellulare</label
              >
              <input
                type="text"
                name="phone"
                id="phone"
                v-model="form.phone"
                autocomplete="phone"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div class="px-4 py-7 bg-white text-right sm:px-6 rounded-b-md">
          <button
            v-if="!isLoading"
            type="submit"
            class="w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Invia messaggio
          </button>
          <div v-else>... sto inviando</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheForm",
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        surname: "",
        email: "",
        message: "",
        business: "",
        phone: "",
      },
    };
  },
  methods: {
    initForm() {
      this.form = {
        name: "",
        surname: "",
        email: "",
        message: "",
        business: "",
        phone: "",
      };
    },
    async sendMessage() {
      this.isLoading = true;
      let url = `https://api.nibble.it/website/contact-us`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.form.name,
          surname: this.form.surname,
          phone: this.form.phone,
          email: this.form.email,
          business: this.form.business,
          message: this.form.message,
          type: "montefumaiolo.net",
        }),
      });

      const responseData = await response.json();

      if (responseData.status_code != "200") {
        const error = new Error(
          responseData.message ||
            "Failed to authenticate. Check your login data."
        );
        throw error;
      }

      console.log(responseData);
      this.initForm();
      this.isLoading = false;
    },
  },
};
</script>
