<template>
  <div class="w-full shadow-md">
    <div class="max-w-7xl mx-auto text-left px-4">
      <div
        class="w-max border-b-4 border-gray-300 pt-2 pb-1 text-2xl lg:text-4xl"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheBreadcrumb",
};
</script>
