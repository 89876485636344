/* eslint-disable */

import TheCam from '@/components/TheCam'
import TheBreadcrumb from '@/components/TheBreadcrumb'

import Menu2Times from '@/components/icons/Menu2Times'
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

const GlobalDirectives = {
    install(Vue) {
        Vue.component("collapse-transition", CollapseTransition);
        Vue.component('i-menu-to-times', Menu2Times);
        Vue.component('the-cam', TheCam);
        Vue.component('the-breadcrumb', TheBreadcrumb);
    },
};

export default GlobalDirectives;
