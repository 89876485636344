<template>
  <footer
    class="bottom-0 bg-white text-gray backdrop-filter backdrop-blur transition-all duration-300 shadow-inner"
  >
    <div class="max-w-7xl mx-auto px-2 lg:px-8 py-1">
      <div class="w-full flex flex-row justify-between items-center">
        <div
          class="text-gray-900 no-underline hover:no-underline font-bold text-md lg:text-sm"
        >
          <router-link to="/">
            <div class="pl-2 lg:pl-4 flex items-center space-x-2 lg:space-x-3">
              <img
                src="@/assets/vector/logo.svg"
                class="w-5 p-px lg:p-0 lg:w-4 h-5 lg:h-4"
                alt=""
              />
              <span class="whitespace-nowrap"> MONTE FUMAIOLO </span>
            </div>
          </router-link>
        </div>

        <div class="text-xs text-right flex-grow pr-2 lg:pr-4">
          <a
            href="https://www.nibble.it"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-indigo-500"
          >
            {{ new Date().getFullYear() }} © Nibble
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
