import { createRouter, createWebHistory } from "vue-router";
import WebcamView from "@/views/WebcamView";
import PartnerView from "@/views/PartnerView";
import ContactView from "@/views/ContactView";

const routes = [
  {
    path: "/",
    name: "default",
    redirect: { name: "webcam" },
  },
  {
    path: "/webcam",
    name: "webcam",
    component: WebcamView,
  },
  {
    path: "/partner",
    name: "partner",
    component: PartnerView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
