/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";
import GlobalDirectives from "./plugins/globalDirectives.js";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./assets/main.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(GlobalDirectives);


app.mount("#app");
