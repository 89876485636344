<template>
  <div class="bg-gray-100 min-h-screen">
    <div>
      <img
        src="@/assets/landscapes/partners-page.jpeg"
        class="object-cover object-center h-96 w-full"
      />
    </div>
    <the-breadcrumb>Partner</the-breadcrumb>

    <div class="max-w-7xl mx-auto pt-17 pb-40">
      <div class="px-4 py-10 text-lg w-full text-left">
        <p class="pb-2">
          Montefumaiolo.net è un progetto nato dall'idea di Nibble Network in
          collaborazione con Fumaiolo Paradise Hotel.
        </p>

        <span class="text-lg w-full text-left text-gray-600">
          Sei interessato a inserire il logo della tua azienda?
          <router-link to="/contact" class="text-indigo-500"
            >Unisciti al programma Partner</router-link
          >
          e sponsorizza la tua attività su questo sito.
        </span>
      </div>

      <div class="flex flex-col items-center px-4 w-max mx-auto my-20">
        <div class="text-2xl font-bold uppercase">Main Sponsor</div>

        <div
          class="flex w-full flex-col md:flex-row md:items-center space-y-5 md:space-y-0 md:space-x-10 py-10 max-w-3xl"
        >
          <div class="p-6 md:p-16 w-64 md:w-1/2">
            <a
              href="https://www.nibble.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/logos/nibble.png" class="inline" alt="" />
            </a>
          </div>
          <div class="p-4 w-64 md:w-1/2">
            <a
              href="http://www.fumaioloparadisehotel.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/logos/paradise.png" class="inline" alt="" />
            </a>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center px-4 w-max mx-auto">
        <div class="text-2xl font-bold uppercase">Sponsor</div>

        <div
          class="flex w-full flex-col md:flex-row md:items-center space-y-5 md:space-y-0 md:space-x-10 py-10 max-w-3xl"
        >
          <div class="p-6 w-52 mx-auto px-10">
            <a
              href="https://www.vergheretotrail.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/logos/verghereto-trail.png"
                class="inline"
                alt=""
              />
            </a>
          </div>
          <div class="p-6 w-52 mx-auto px-10">
            <a
              href="https://www.radiostudiodelta.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/logos/rsd.png" class="inline" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.containerb {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
