<template>
  <div>
    <div
      v-if="overlayed == id"
      @click="toggle()"
      :class="
        overlayed == id
          ? 'bg-opacity-70 backdrop-filter backdrop-blur z-40'
          : 'opacity-0 z-0'
      "
      class="absolute left-0 top-0 bg-gray-800 w-screen h-screen transition-all duration-300"
    ></div>
    <div
      class="transition-all duration-300"
      :class="overlayed == id ? 'absolute w-full top-0 left-0 z-50' : ''"
    >
      <div @click="toggle()">
        <img
          :class="overlayed == id ? 'p-10' : 'rounded-xl hover:opacity-50'"
          class="mx-auto transition-all duration-300 cursor-pointer"
          :src="url + '?rnd=' + cacheKey"
        />
      </div>

      <!-- <div v-if="overlayed == id" class="w-min mx-auto flex items-center">
        <div class="whitespace-nowrap text-gray-50 pr-4">Refresh rate:</div>
        <div class="rounded bg-gray-200 px-2 py-1 flex space-x-2">
          <div
            @click="setRR(1)"
            :class="refreshRate == 1 ? 'text-indigo-500 font-bold' : ''"
            class="cursor-pointer hover:font-semibold"
          >
            1s
          </div>
          <div
            @click="setRR(3)"
            :class="refreshRate == 3 ? 'text-indigo-500 font-bold' : ''"
            class="cursor-pointer hover:font-semibold"
          >
            3s
          </div>
          <div
            @click="setRR(5)"
            :class="refreshRate == 5 ? 'text-indigo-500 font-bold' : ''"
            class="cursor-pointer hover:font-semibold"
          >
            5s
          </div>
          <div
            @click="setRR(10)"
            :class="refreshRate == 10 ? 'text-indigo-500 font-bold' : ''"
            class="cursor-pointer hover:font-semibold"
          >
            10s
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TheCam",
  props: ["url", "id"],
  emits: ["free"],
  data() {
    return {
      cacheKey: +new Date(),
      interval: null,
      refreshRate: 5,
    };
  },
  computed: {
    overlayed() {
      return this.$store.getters["overlayed"];
    },
  },
  mounted() {
    this.si();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    si() {
      this.interval = setInterval(() => {
        this.cacheKey = +new Date();
      }, this.refreshRate * 1000);
    },
    toggle() {
      console.log("cacca");
      if (this.overlayed) {
        this.$store.commit("setOverlay", { id: null });
        this.refreshRate = 5;
      } else this.$store.commit("setOverlay", { id: this.id });
    },
    setRR(rr) {
      clearInterval(this.interval);
      this.refreshRate = rr;
      this.si();
    },
  },
};
</script>
