<template>
  <div>
    <div
      @click="isOpen ? toggle() : null"
      class="fixed top-0 bg-white h-screen w-screen transition-all duration-300"
      :class="
        isOpen
          ? 'bg-opacity-70 backdrop-filter backdrop-blur z-20'
          : 'opacity-0 z-alfa'
      "
    ></div>
    <nav
      class="fixed w-full z-30 top-0 bg-white bg-opacity-60 text-gray backdrop-filter backdrop-blur transition-all duration-300 shadow-sm hover:shadow-xl"
    >
      <div class="max-w-7xl mx-auto flex items-center justify-between mt-0">
        <div
          class="text-gray-900 no-underline hover:no-underline font-bold text-2xl lg:text-4xl pt-3 lg:pt-4 pb-3 lg:pb-2"
        >
          <router-link to="/">
            <div class="pl-4 flex items-center space-x-2 lg:space-x-3">
              <img
                src="@/assets/vector/logo.svg"
                class="w-7 p-px lg:p-0 lg:w-9 h-7 lg:h-9"
                alt=""
                srcset=""
              />
              <span class="whitespace-nowrap"> MONTE FUMAIOLO </span>
            </div>
          </router-link>
        </div>

        <div class="flex-shrink">
          <div
            class="hidden lg:flex justify-evenly w-full pr-4 uppercase text-right space-x-6"
          >
            <div v-for="(item, index) in menu.items" :key="index">
              <router-link
                :to="item.href"
                class="inline-block py-5 font-bold no-underline whitespace-nowrap text-gray-700 border-b-4 border-transparent hover:border-gray-900 hover:text-gray-900"
              >
                {{ item.label }}
              </router-link>
            </div>
          </div>
          <div class="block lg:hidden pr-4">
            <i-menu-to-times
              @clicked="toggle()"
              :isOpen="isOpen"
            ></i-menu-to-times>
          </div>
        </div>
      </div>
      <div
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto lg:bg-transparent text-black z-20"
      >
        <div
          :class="isOpen ? 'p-4 pb-0' : 'ml-40'"
          class="transition-all duration-500 grid grid-cols-1 text-base"
        >
          <collapse-transition
            v-for="(item, index) in menu.items"
            :key="index"
            class="uppercase text-right border-b border-gray-400 text-gray-700 hover:text-gray-900"
            :duration="getTransition(index)"
          >
            <div v-show="isOpen" class="py-1">
              <router-link
                @click.prevent="toggle()"
                :to="item.href"
                class="inline-block py-2 font-bold no-underline whitespace-nowrap"
              >
                {{ item.label }}
              </router-link>
            </div>
          </collapse-transition>
        </div>
        <collapse-transition :duration="300">
          <img
            v-show="isOpen"
            class="w-full h-full pt-4 opacity-50"
            src="@/assets/png/base-mobile-menu.png"
          />
        </collapse-transition>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      isOpen: false,
      menu: {
        items: [
          {
            label: "Webcam",
            href: "/webcam",
          },
          {
            label: "Partner",
            href: "/partner",
          },
          {
            label: "Contatti",
            href: "/contact",
          },
        ],
      },
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    getTransition(index) {
      return (index + 2) * 100;
    },
  },
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.pippo {
  background-image: url("@/assets/png/base-mobile-menu.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: transparent;
}

.z-alfa {
  z-index: -100;
}

.router-link-active {
  @apply border-gray-900;
  @apply text-gray-900;
}
</style>
