<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto px-2 lg:px-16 py-10 lg:pt-20 lg:pb-32">
      <div class="my-5 lg:my-20">
        <div class="border-b-4 border-gray-800 w-16 mx-auto h-px"></div>
        <div
          class="uppercase w-max mx-auto mt-4 text-2xl font-bold lg:text-4xl"
        >
          Sponsor
        </div>
      </div>
      <div class="pb-5 lg:pb-20">
        <div class="flex flex-col items-center">
          <a
            href="https://www.nibble.it/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/logos/nibble.png" class="inline h-40" alt="" />
          </a>
        </div>
      </div>
      <div
        class="grid grod-cols-1 md:grid-cols-3 gap-10 md:gap-24 px-10 md:px-4 items-center justify-center"
      >
        <div class="flex flex-col items-center">
          <a
            href="https://www.vergheretotrail.it/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/logos/verghereto-trail.png"
              class="inline h-32"
              alt=""
            />
          </a>
        </div>
        <div class="flex flex-col items-center">
          <a
            href="http://www.fumaioloparadisehotel.it/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/logos/paradise.png" class="inline h-32" alt="" />
          </a>
        </div>
        <div class="flex flex-col items-center">
          <a
            href="https://www.radiostudiodelta.it/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/logos/rsd.png" class="inline h-32" alt="" />
          </a>
        </div>

        <!-- <div class="px-6 py-4">
          <div
            class="flex flex-col items-center bg-gray-200 rounded bg-opacity-70 w-full h-32 md:36 lg:h-40 justify-center hover:shadow"
          >
            <div class="flex items-center">
              <span class="mx-auto text-xl font-mono">
                Spazio disponibile
              </span>
            </div>
            <div class="flex items-center">
              <span class="mx-auto w-2/3 mt-3 text-sm font-mono">
                Contattaci per acquistare questo spazio pubblicitario
              </span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SponsorSection",
};
</script>
