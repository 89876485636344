<template>
  <div class="bg-gray-100 min-h-screen">
    <div>
      <img
        src="@/assets/landscapes/contacts-page.jpeg"
        class="object-cover object-center h-96 w-full"
      />
    </div>
    <the-breadcrumb>Contatti</the-breadcrumb>
    <div
      class="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center pt-17 pb-40"
    >
      <div class="w-full px-4 md:px-10 lg:px-4 lg:w-2/5 mt-4 lg:mt-20 mb-10">
        <div class="mt-10 sm:mt-0">
          <div class="shadow-xl rounded-md">
            <the-form></the-form>
          </div>
        </div>
      </div>
      <div
        class="w-full px-4 md:px-10 lg:w-3/5 mt-10 lg:mt-20 lg:mb-10 text-left"
      >
        <div class="flex flex-col space-y-8 text-gray-700">
          <div class="text-3xl font-semibold">Come possiamo aiutarti?</div>
          <div class="text-lg">
            Sentiti libero di scriverci per maggiori informazioni o per altri
            tipi di richieste.
          </div>
          <div class="lg:w-2/3 flex flex-col space-y-10">
            <div>
              <p class="text-lg font-semibold">Diventa un partner</p>
              <p class="text-md">
                Unisciti al nostro programma Partner e sponsorizza la tua
                attività su questo sito.
              </p>
            </div>
            <div>
              <p class="text-lg font-semibold">Nuovi progetti</p>
              <p class="text-md">
                Realizza il tuo progetto di webcam e valorizza il tuo
                territorio.
              </p>
            </div>
            <div>
              <p class="text-lg font-semibold">Info e curiosità</p>
              <p class="text-md">
                Scrivi per qualsiasi tipo di informazione riguardanti il
                progetto montefumaiolo.net
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheForm from "@/components/TheForm";

export default {
  name: "ContactView",
  components: { TheForm },
};
</script>
