<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto px-4 lg:px-16 py-10 lg:pt-20 lg:pb-32">
      <div class="my-20">
        <div class="border-b-4 border-gray-800 w-16 mx-auto h-px"></div>
        <div
          class="uppercase w-max mx-auto mt-4 text-2xl font-bold lg:text-4xl"
        >
          YouTube Channel
        </div>
        <div class="pt-5 text-lg">
          Scopri tutte le meravigliose immagini unite in timelapse.
          <a
            href="https://www.youtube.com/watch?v=WSFDuQfFdJ8"
            target="_blank"
            rel="noopener noreferrer"
            class="text-indigo-500"
          >
            Vai al canale...
          </a>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 gap-10">
        <div class="containerb">
          <iframe
            width="560"
            class="video rounded-xl"
            height="315"
            src="https://www.youtube-nocookie.com/embed/WSFDuQfFdJ8?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="containerb">
          <iframe
            width="560"
            class="video rounded-xl"
            height="315"
            src="https://www.youtube-nocookie.com/embed/h-isxh28rZU?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoutubeSection",
};
</script>

<style scoped>
.containerb {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
